import React, { useState, useEffect, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { 
  getAnalytics, 
  logEvent, 
  setUserProperties,
  setUserId,
  setCurrentScreen
} from 'firebase/analytics';
import { LogOut, User, AlertCircle, CheckCircle, X, ChevronDown, ChevronUp, ArrowDown } from 'lucide-react';
import { searchApi } from './services/api';

// Initialize Firebase - Replace with your config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID // Add this for analytics
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();


// Add the full country mapping
const COUNTRY_MAPPING = {
  "zz": "🌍 Global",
  "eu": "🇪🇺 European Union",
  "un": "🇺🇳 United Nations",
  "zr": "🇨🇩 Zaire",
  "cz": "🇨🇿 Czech Republic",
  "xk": "🇽🇰 Kosovo",
  "dd": "🇩🇪 East Germany",
  "yucs": "🇾🇪 Yugoslavia",
  "csxx": "🇷🇸 Serbia and Montenegro",
  "cshh": "🇨🇿 Czechoslovakia",
  "suhh": "🇷🇺 Soviet Union",
  "ge-ab": "🏴 Abkhazia (Occupied Georgia)",
  "x-so": "🏴 South Ossetia (Occupied Georgia)",
  "ua-lpr": "🏴 Luhansk (Occupied Ukraine)",
  "ua-dpr": "🏴 Donetsk (Occupied Ukraine)",
  "ua-cri": "🏴 Crimea (Occupied Ukraine)",
  "so-som": "🏴 Somaliland",
  "cy-trnc": "🏴 Northern Cyprus",
  "az-nk": "🏴 Nagorno-Karabakh",
  "iq-kr": "🏴 Kurdistan",
  "cn-xz": "🏴 Tibet",
  "cq": "🏴 Sark",
  "gb-wls": "🏴󠁧󠁢󠁷󠁬󠁳󠁿 Wales",
  "gb-sct": "🏴󠁧󠁢󠁳󠁣󠁴󠁿 Scotland",
  "gb-nir": "🇬🇧 Northern Ireland",
  "md-pmr": "🏴 Transnistria (PMR)",
  "pk-km": "🏴 Kashmir",
  "ac": "🇬🇧 Ascension Island",
  "ad": "🇦🇩 Andorra",
  "ae": "🇦🇪 United Arab Emirates",
  "af": "🇦🇫 Afghanistan",
  "ag": "🇦🇬 Antigua & Barbuda",
  "ai": "🇦🇮 Anguilla",
  "al": "🇦🇱 Albania",
  "am": "🇦🇲 Armenia",
  "ao": "🇦🇴 Angola",
  "aq": "🇦🇶 Antarctica",
  "ar": "🇦🇷 Argentina",
  "as": "🇦🇸 American Samoa",
  "at": "🇦🇹 Austria",
  "au": "🇦🇺 Australia",
  "aw": "🇦🇼 Aruba",
  "ax": "🇦🇽 Åland Islands",
  "az": "🇦🇿 Azerbaijan",
  "ba": "🇧🇦 Bosnia & Herzegovina",
  "bb": "🇧🇧 Barbados",
  "bd": "🇧🇩 Bangladesh",
  "be": "🇧🇪 Belgium",
  "bf": "🇧🇫 Burkina Faso",
  "bg": "🇧🇬 Bulgaria",
  "bh": "🇧🇭 Bahrain",
  "bi": "🇧🇮 Burundi",
  "bj": "🇧🇯 Benin",
  "bl": "🇧🇱 St. Barthélemy",
  "bm": "🇧🇲 Bermuda",
  "bn": "🇧🇳 Brunei",
  "bo": "🇧🇴 Bolivia",
  "bq": "🇧🇶 Caribbean Netherlands",
  "br": "🇧🇷 Brazil",
  "bs": "🇧🇸 Bahamas",
  "bt": "🇧🇹 Bhutan",
  "bv": "🇧🇻 Bouvet Island",
  "bw": "🇧🇼 Botswana",
  "by": "🇧🇾 Belarus",
  "bz": "🇧🇿 Belize",
  "ca": "🇨🇦 Canada",
  "cc": "🇨🇨 Cocos (Keeling) Islands",
  "cd": "🇨🇩 Congo - Kinshasa",
  "cf": "🇨🇫 Central African Republic",
  "cg": "🇨🇬 Congo - Brazzaville",
  "ch": "🇨🇭 Switzerland",
  "ci": "🇨🇮 Côte d’Ivoire",
  "ck": "🇨🇰 Cook Islands",
  "cl": "🇨🇱 Chile",
  "cm": "🇨🇲 Cameroon",
  "cn": "🇨🇳 China",
  "co": "🇨🇴 Colombia",
  "cp": "🇨🇵 Clipperton Island",
  "cr": "🇨🇷 Costa Rica",
  "cu": "🇨🇺 Cuba",
  "cv": "🇨🇻 Cape Verde",
  "cw": "🇨🇼 Curaçao",
  "cx": "🇨🇽 Christmas Island",
  "cy": "🇨🇾 Cyprus",
  "de": "🇩🇪 Germany",
  "dg": "🇩🇬 Diego Garcia",
  "dj": "🇩🇯 Djibouti",
  "dk": "🇩🇰 Denmark",
  "dm": "🇩🇲 Dominica",
  "do": "🇩🇴 Dominican Republic",
  "dz": "🇩🇿 Algeria",
  "ec": "🇪🇨 Ecuador",
  "ee": "🇪🇪 Estonia",
  "eg": "🇪🇬 Egypt",
  "eh": "🇪🇭 Western Sahara",
  "er": "🇪🇷 Eritrea",
  "es": "🇪🇸 Spain",
  "et": "🇪🇹 Ethiopia",
  "fi": "🇫🇮 Finland",
  "fj": "🇫🇯 Fiji",
  "fk": "🇫🇰 Falkland Islands",
  "fm": "🇫🇲 Micronesia",
  "fo": "🇫🇴 Faroe Islands",
  "fr": "🇫🇷 France",
  "ga": "🇬🇦 Gabon",
  "gb": "🇬🇧 United Kingdom",
  "gd": "🇬🇩 Grenada",
  "ge": "🇬🇪 Georgia",
  "gf": "🇬🇫 French Guiana",
  "gg": "🇬🇬 Guernsey",
  "gh": "🇬🇭 Ghana",
  "gi": "🇬🇮 Gibraltar",
  "gl": "🇬🇱 Greenland",
  "gm": "🇬🇲 Gambia",
  "gn": "🇬🇳 Guinea",
  "gp": "🇬🇵 Guadeloupe",
  "gq": "🇬🇶 Equatorial Guinea",
  "gr": "🇬🇷 Greece",
  "gs": "🇬🇸 South Georgia & South Sandwich Islands",
  "gt": "🇬🇹 Guatemala",
  "gu": "🇬🇺 Guam",
  "gw": "🇬🇼 Guinea-Bissau",
  "gy": "🇬🇾 Guyana",
  "hk": "🇭🇰 Hong Kong SAR China",
  "hm": "🇭🇲 Heard & McDonald Islands",
  "hn": "🇭🇳 Honduras",
  "hr": "🇭🇷 Croatia",
  "ht": "🇭🇹 Haiti",
  "hu": "🇭🇺 Hungary",
  "ic": "🇮🇨 Canary Islands",
  "id": "🇮🇩 Indonesia",
  "ie": "🇮🇪 Ireland",
  "il": "🇮🇱 Israel",
  "im": "🇮🇲 Isle of Man",
  "in": "🇮🇳 India",
  "io": "🇮🇴 British Indian Ocean Territory",
  "iq": "🇮🇶 Iraq",
  "ir": "🇮🇷 Iran",
  "is": "🇮🇸 Iceland",
  "it": "🇮🇹 Italy",
  "je": "🇯🇪 Jersey",
  "jm": "🇯🇲 Jamaica",
  "jo": "🇯🇴 Jordan",
  "jp": "🇯🇵 Japan",
  "ke": "🇰🇪 Kenya",
  "kg": "🇰🇬 Kyrgyzstan",
  "kh": "🇰🇭 Cambodia",
  "ki": "🇰🇮 Kiribati",
  "km": "🇰🇲 Comoros",
  "kn": "🇰🇳 St. Kitts & Nevis",
  "kp": "🇰🇵 North Korea",
  "kr": "🇰🇷 South Korea",
  "kw": "🇰🇼 Kuwait",
  "ky": "🇰🇾 Cayman Islands",
  "kz": "🇰🇿 Kazakhstan",
  "la": "🇱🇦 Laos",
  "lb": "🇱🇧 Lebanon",
  "lc": "🇱🇨 St. Lucia",
  "li": "🇱🇮 Liechtenstein",
  "lk": "🇱🇰 Sri Lanka",
  "lr": "🇱🇷 Liberia",
  "ls": "🇱🇸 Lesotho",
  "lt": "🇱🇹 Lithuania",
  "lu": "🇱🇺 Luxembourg",
  "lv": "🇱🇻 Latvia",
  "ly": "🇱🇾 Libya",
  "ma": "🇲🇦 Morocco",
  "mc": "🇲🇨 Monaco",
  "md": "🇲🇩 Moldova",
  "me": "🇲🇪 Montenegro",
  "mf": "🇲🇫 St. Martin",
  "mg": "🇲🇬 Madagascar",
  "mh": "🇲🇭 Marshall Islands",
  "mk": "🇲🇰 North Macedonia",
  "ml": "🇲🇱 Mali",
  "mm": "🇲🇲 Myanmar (Burma)",
  "mn": "🇲🇳 Mongolia",
  "mo": "🇲🇴 Macao SAR China",
  "mp": "🇲🇵 Northern Mariana Islands",
  "mq": "🇲🇶 Martinique",
  "mr": "🇲🇷 Mauritania",
  "ms": "🇲🇸 Montserrat",
  "mt": "🇲🇹 Malta",
  "mu": "🇲🇺 Mauritius",
  "mv": "🇲🇻 Maldives",
  "mw": "🇲🇼 Malawi",
  "mx": "🇲🇽 Mexico",
  "my": "🇲🇾 Malaysia",
  "mz": "🇲🇿 Mozambique",
  "na": "🇳🇦 Namibia",
  "nc": "🇳🇨 New Caledonia",
  "ne": "🇳🇪 Niger",
  "nf": "🇳🇫 Norfolk Island",
  "ng": "🇳🇬 Nigeria",
  "ni": "🇳🇮 Nicaragua",
  "nl": "🇳🇱 Netherlands",
  "no": "🇳🇴 Norway",
  "np": "🇳🇵 Nepal",
  "nr": "🇳🇷 Nauru",
  "nu": "🇳🇺 Niue",
  "nz": "🇳🇿 New Zealand",
  "om": "🇴🇲 Oman",
  "pa": "🇵🇦 Panama",
  "pe": "🇵🇪 Peru",
  "pf": "🇵🇫 French Polynesia",
  "pg": "🇵🇬 Papua New Guinea",
  "ph": "🇵🇭 Philippines",
  "pk": "🇵🇰 Pakistan",
  "pl": "🇵🇱 Poland",
  "pm": "🇵🇲 St. Pierre & Miquelon",
  "pn": "🇵🇳 Pitcairn Islands",
  "pr": "🇵🇷 Puerto Rico",
  "ps": "🇵🇸 Palestinian Territories",
  "pt": "🇵🇹 Portugal",
  "pw": "🇵🇼 Palau",
  "py": "🇵🇾 Paraguay",
  "qa": "🇶🇦 Qatar",
  "re": "🇷🇪 Réunion",
  "ro": "🇷🇴 Romania",
  "rs": "🇷🇸 Serbia",
  "ru": "🇷🇺 Russia",
  "rw": "🇷🇼 Rwanda",
  "sa": "🇸🇦 Saudi Arabia",
  "sb": "🇸🇧 Solomon Islands",
  "sc": "🇸🇨 Seychelles",
  "sd": "🇸🇩 Sudan",
  "se": "🇸🇪 Sweden",
  "sg": "🇸🇬 Singapore",
  "sh": "🇸🇭 St. Helena",
  "si": "🇸🇮 Slovenia",
  "sj": "🇸🇯 Svalbard & Jan Mayen",
  "sk": "🇸🇰 Slovakia",
  "sl": "🇸🇱 Sierra Leone",
  "sm": "🇸🇲 San Marino",
  "sn": "🇸🇳 Senegal",
  "so": "🇸🇴 Somalia",
  "sr": "🇸🇷 Suriname",
  "ss": "🇸🇸 South Sudan",
  "st": "🇸🇹 São Tomé & Príncipe",
  "sv": "🇸🇻 El Salvador",
  "sx": "🇸🇽 Sint Maarten",
  "sy": "🇸🇾 Syria",
  "sz": "🇸🇿 Eswatini",
  "tc": "🇹🇨 Turks & Caicos Islands",
  "td": "🇹🇩 Chad",
  "tf": "🇹🇫 French Southern Territories",
  "tg": "🇹🇬 Togo",
  "th": "🇹🇭 Thailand",
  "tj": "🇹🇯 Tajikistan",
  "tk": "🇹🇰 Tokelau",
  "tl": "🇹🇱 Timor-Leste",
  "tm": "🇹🇲 Turkmenistan",
  "tn": "🇹🇳 Tunisia",
  "to": "🇹🇴 Tonga",
  "tr": "🇹🇷 Turkey",
  "tt": "🇹🇹 Trinidad & Tobago",
  "tv": "🇹🇻 Tuvalu",
  "tw": "🇹🇼 Taiwan",
  "tz": "🇹🇿 Tanzania",
  "ua": "🇺🇦 Ukraine",
  "ug": "🇺🇬 Uganda",
  "us": "🇺🇸 United States",
  "uy": "🇺🇾 Uruguay",
  "uz": "🇺🇿 Uzbekistan",
  "va": "🇻🇦 Vatican City",
  "vc": "🇻🇨 St. Vincent & Grenadines",
  "ve": "🇻🇪 Venezuela",
  "vg": "🇻🇬 British Virgin Islands",
  "vi": "🇻🇮 U.S. Virgin Islands",
  "vn": "🇻🇳 Vietnam",
  "vu": "🇻🇺 Vanuatu",
  "wf": "🇼🇫 Wallis & Futuna",
  "ws": "🇼🇸 Samoa",
  "xk": "🇽🇰 Kosovo",
  "ye": "🇾🇪 Yemen",
  "yt": "🇾🇹 Mayotte",
  "za": "🇿🇦 South Africa",
  "zm": "🇿🇲 Zambia",
  "zw": "🇿🇼 Zimbabwe"
};


const getOpenPerplexCountry = (country) => {
  const openPerplexCountryMapping = {
    "🇺🇸 United States": "us",
    "🇨🇦 Canada": "ca",
    "🇬🇧 United Kingdom": "uk",
    "🇲🇽 Mexico": "mx",
    "🇪🇸 Spain": "es",
    "🇩🇪 Germany": "de",
    "🇫🇷 France": "fr",
    "🇵🇹 Portugal": "pt",
    "🇧🇪 Belgium": "be",
    "🇳🇱 Netherlands": "nl",
    "🇨🇭 Switzerland": "ch",
    "🇳🇴 Norway": "no",
    "🇸🇪 Sweden": "se",
    "🇦🇹 Austria": "at",
    "🇩🇰 Denmark": "dk",
    "🇫🇮 Finland": "fi",
    "🇹🇷 Turkey": "tr",
    "🇮🇹 Italy": "it",
    "🇵🇱 Poland": "pl",
    "🇷🇺 Russia": "ru",
    "🇿🇦 South Africa": "za",
    "🇦🇪 United Arab Emirates": "ae",
    "🇸🇦 Saudi Arabia": "sa",
    "🇦🇷 Argentina": "ar",
    "🇧🇷 Brazil": "br",
    "🇦🇺 Australia": "au",
    "🇨🇳 China": "cn",
    "🇰🇷 South Korea": "kr",
    "🇯🇵 Japan": "jp",
    "🇮🇳 India": "in",
    "🇵🇸 Palestine": "ps",
    "🇰🇼 Kuwait": "kw",
    "🇴🇲 Oman": "om",
    "🇶🇦 Qatar": "qa",
    "🇮🇱 Israel": "il",
    "🇲🇦 Morocco": "ma",
    "🇪🇬 Egypt": "eg",
    "🇮🇷 Iran": "ir",
    "🇱🇾 Libya": "ly",
    "🇾🇪 Yemen": "ye",
    "🇮🇩 Indonesia": "id",
    "🇵🇰 Pakistan": "pk",
    "🇧🇩 Bangladesh": "bd",
    "🇲🇾 Malaysia": "my",
    "🇵🇭 Philippines": "ph",
    "🇹🇭 Thailand": "th",
    "🇻🇳 Vietnam": "vn"
  };
  return openPerplexCountryMapping[country] || "us";
};


const getCountryName = (code) => {
  if (!code) return '';
  if (Array.isArray(code)) {
    return code.map(c => COUNTRY_MAPPING[c.toLowerCase()] || c).join(', ');
  }
  return COUNTRY_MAPPING[code.toLowerCase()] || code;
};


const formatDate = (dateStr) => {
  if (!dateStr) return '';
  try {
    if (Array.isArray(dateStr)) {
      dateStr = dateStr[0];
    }
    return new Date(dateStr).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (e) {
    return dateStr;
  }
};


const DatePicker = ({ value, onChange }) => {
  const [focused, setFocused] = useState(false);
  const dateInputRef = useRef(null);
  const formattedDate = value ? new Date(value).toLocaleDateString() : '';

  const handleClick = () => {
    dateInputRef.current?.showPicker();
  };

  return (
    <div className="relative" onClick={handleClick}>
      <div className={`input-field flex justify-between cursor-pointer ${focused ? 'text-gray-900' : 'text-gray-500'}`}>
        <span>{value ? formattedDate : 'Date of Birth'}</span>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ChevronDown size={20} className="text-gray-400" />
        </div>
      </div>
      <input
        ref={dateInputRef}
        type="date"
        className="opacity-0 absolute inset-0 cursor-pointer"
        value={value}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};


const GenderSelect = ({ value, onChange }) => {
  const [focused, setFocused] = useState(false);
  
  return (
    <div className="relative">
      <select
        className={`input-field appearance-none ${(!value || value === "") ? 'text-gray-500' : 'text-gray-900'}`}
        value={value || ""}
        onChange={onChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <option value="" disabled>Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
        <option value="Other">Other</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ChevronDown size={20} className="text-gray-400" />
      </div>
    </div>
  );
};


const getResultTitle = (result) => {
  if (!result.caption) return 'Unnamed Result';
  if (result.id === 'no_sanctions') {
    return result.caption.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  return result.caption;
};


const formatLLMResponse = (text) => {
  if (!text) return '';
  const paragraphs = text.split('\n');
  return paragraphs.map((paragraph, index) => {
    if (!paragraph) return '';
    
    const withLinks = paragraph.replace(
      /\[([^\]]+)\]\(([^)]+)\)/g,
      '<a href="$2" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800">$1</a>'
    );

    for (let i = 6; i >= 1; i--) {
      const headerMatch = withLinks.match(new RegExp(`^#{${i}}\\s+(.+)$`));
      if (headerMatch) {
        return `<h${i} class="text-${i === 1 ? '2xl' : i === 2 ? 'xl' : 'lg'} font-bold my-3">${headerMatch[1]}</h${i}>`;
      }
    }

    const withBold = withLinks.replace(
      /\*\*([^*]+)\*\*/g,
      '<strong>$1</strong>'
    );

    return `<p class="mb-3">${withBold}</p>`;
  }).join('');
};


const getRiskTag = (topic) => {
  const riskTagMapping = {
    "crime": "Crime",
    "crime.fraud": "Fraud",
    "crime.cyber": "Cybercrime",
    "crime.fin": "Financial crime",
    "crime.env": "Environmental violations",
    "crime.theft": "Theft",
    "crime.war": "War crimes",
    "crime.boss": "Criminal leadership",
    "crime.terror": "Terrorism",
    "crime.traffick": "Trafficking",
    "crime.traffick.drug": "Drug trafficking",
    "crime.traffick.human": "Human trafficking",
    "wanted": "Wanted",
    "corp.offshore": "Offshore",
    "corp.shell": "Shell company",
    "corp.public": "Public listed company",
    "corp.disqual": "Disqualified",
    "gov": "Government",
    "gov.national": "National government",
    "gov.state": "State government",
    "gov.muni": "Municipal government",
    "gov.soe": "State-owned enterprise",
    "gov.igo": "Intergovernmental organization",
    "gov.head": "Head of government or state",
    "gov.admin": "Civil service",
    "gov.executive": "Executive branch",
    "gov.legislative": "Legislative branch",
    "gov.judicial": "Judicial branch",
    "gov.security": "Security services",
    "gov.financial": "Central banking",
    "fin": "Financial services",
    "fin.bank": "Bank",
    "fin.fund": "Fund",
    "fin.advisor": "Financial advisor",
    "reg.action": "Regulator action",
    "reg.warn": "Regulator warning",
    "role.pep": "Politician",
    "role.pol": "Non-PEP",
    "role.rca": "Close Associate",
    "role.judge": "Judge",
    "role.civil": "Civil servant",
    "role.diplo": "Diplomat",
    "role.lawyer": "Lawyer",
    "role.acct": "Accountant",
    "role.spy": "Intelligence Officer",
    "role.oligarch": "Oligarch",
    "role.journo": "Journalist",
    "role.act": "Activist",
    "role.lobby": "Lobbyist",
    "pol.party": "Political party",
    "pol.union": "Union",
    "rel": "Religion",
    "mil": "Military",
    "asset.frozen": "Frozen asset",
    "sanction": "Sanctioned entity",
    "sanction.linked": "Sanction-linked entity",
    "sanction.counter": "Counter-sanctioned entity",
    "export.control": "Export controlled",
    "export.risk": "Trade risk",
    "debarment": "Debarred entity",
    "poi": "Person of interest"
  };
  return riskTagMapping[topic] || topic;
};


const renderLinks = (links, caption) => {
  if (!links?.length) return caption;
  return (
    <a 
      href={links[0]} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="text-blue-600 hover:text-blue-800"
    >
      {caption}
    </a>
  );
};


const UserMenu = ({ user, onSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      logEvent(analytics, 'sign_out', { success: true });
      onSignOut();
    } catch (error) {
      logEvent(analytics, 'sign_out_error', {
        error_code: error.code,
        error_message: error.message
      });
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        aria-label="User menu"
      >
        <User className="w-5 h-5 text-primary-600" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {/* User info section */}
            <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
              <div className="font-medium truncate">{user.email}</div>
              <div className="text-xs text-gray-500">
                Joined {new Date(user.metadata.creationTime).toLocaleDateString()}
              </div>
            </div>
            
            {/* Menu items */}
            <button
              onClick={handleSignOut}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Sign out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


// Simple Alert Component
const Alert = ({ children, variant = 'default', onClose }) => {
  const variants = {
    default: 'bg-blue-50 text-blue-900 border-blue-200',
    destructive: 'bg-red-50 text-red-900 border-red-200',
    success: 'bg-green-50 text-green-900 border-green-200'
  };

  return (
    <div className={`rounded-lg border p-4 mb-4 ${variants[variant]}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {variant === 'destructive' && <AlertCircle className="h-4 w-4" />}
          {variant === 'success' && <CheckCircle className="h-4 w-4" />}
          <div>{children}</div>
        </div>
        {onClose && (
          <button 
            onClick={onClose}
            className="rounded-full p-1 hover:bg-black hover:bg-opacity-10"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
    </div>
  );
};


const LandingPage = ({ onLogin }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  useEffect(() => {
    setCurrentScreen(analytics, isSignUp ? 'signup_page' : 'login_page');
  }, [isSignUp]);

  const handleAuth = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
        logEvent(analytics, 'sign_up', {
          method: 'email',
          success: true
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        logEvent(analytics, 'login', {
          method: 'email',
          success: true
        });
      }
      onLogin();
    } catch (error) {
      logEvent(analytics, isSignUp ? 'sign_up_error' : 'login_error', {
        method: 'email',
        error_code: error.code,
        error_message: error.message
      });
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError('');
      setSuccess('');
      const result = await signInWithPopup(auth, googleProvider);
      logEvent(analytics, isSignUp ? 'sign_up' : 'login', {
        method: 'google',
        success: true
      });
      onLogin();
    } catch (error) {
      logEvent(analytics, 'google_sign_in_error', {
        error_code: error.code,
        error_message: error.message
      });
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent! Please check your inbox.');
      setIsResetModalOpen(false);
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Desktop Layout */}
      <div className="hidden md:flex min-h-screen">
        {/* Left Panel */}
        <div className="flex-1 flex flex-col justify-center items-center px-12 bg-white">
          <div className="max-w-lg text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Introducing Hound
            </h1>
            <p className="text-xl text-gray-600">
              Your AI assistant for instant up-to-date due diligence
            </p>
          </div>
        </div>

        {/* Center Divider */}
        <div className="w-px bg-gray-200 self-stretch my-16" />

        {/* Right Panel */}
        <div className="flex-1 flex items-center justify-center px-12">
          <div className="max-w-md w-full">
            <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
              {isSignUp ? 'Create your account' : 'Sign in to your account'}
            </h2>
            
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 mb-6"
            >
              <img
                className="h-5 w-5 mr-2"
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
              />
              Continue with Google
            </button>

            <div className="relative mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-50 text-gray-500">
                  Or continue with email
                </span>
              </div>
            </div>

            <form onSubmit={handleAuth} className="space-y-4">
              <input
                type="email"
                required
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              />
              <input
                type="password"
                required
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              />

              {!isSignUp && (
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setIsResetModalOpen(true)}
                    className="text-sm text-primary-600 hover:text-primary-500"
                  >
                    Forgot your password?
                  </button>
                </div>
              )}

              {error && (
                <div className="text-red-500 text-sm text-center">{error}</div>
              )}

              {success && (
                <div className="text-green-500 text-sm text-center">{success}</div>
              )}

              <button
                type="submit"
                className="w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </button>
            </form>

            <button
              onClick={() => {
                setIsSignUp(!isSignUp);
                setError('');
                setSuccess('');
              }}
              className="w-full mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="md:hidden min-h-screen flex flex-col">
        {/* Top Section */}
        <div className="flex-1 flex flex-col justify-center items-center px-6 pt-12 pb-8 bg-white">
          <div className="max-w-lg text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Introducing Hound
            </h1>
            <p className="text-xl text-gray-600">
              Your AI assistant for instant up-to-date due diligence
            </p>
          </div>
          <div className="mt-12 animate-bounce">
            <ArrowDown className="h-8 w-8 text-gray-400" />
          </div>
        </div>

        {/* Bottom Section - Same as desktop right panel */}
        <div className="flex-1 px-6 py-12">
          <div className="max-w-md mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
              {isSignUp ? 'Create your account' : 'Sign in to your account'}
            </h2>
            
            {/* Rest of the form - same as desktop */}
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 mb-6"
            >
              <img
                className="h-5 w-5 mr-2"
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
              />
              Continue with Google
            </button>

            {/* Email form - same as desktop */}
            <div className="relative mb-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-gray-50 text-gray-500">
                  Or continue with email
                </span>
              </div>
            </div>

            <form onSubmit={handleAuth} className="space-y-4">
              <input
                type="email"
                required
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              />
              <input
                type="password"
                required
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              />

              {!isSignUp && (
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setIsResetModalOpen(true)}
                    className="text-sm text-primary-600 hover:text-primary-500"
                  >
                    Forgot your password?
                  </button>
                </div>
              )}

              {error && (
                <div className="text-red-500 text-sm text-center">{error}</div>
              )}

              {success && (
                <div className="text-green-500 text-sm text-center">{success}</div>
              )}

              <button
                type="submit"
                className="w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {isSignUp ? 'Sign Up' : 'Sign In'}
              </button>
            </form>

            <button
              onClick={() => {
                setIsSignUp(!isSignUp);
                setError('');
                setSuccess('');
              }}
              className="w-full mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {isSignUp ? 'Already have an account? Sign In' : 'Need an account? Sign Up'}
            </button>
          </div>
        </div>
      </div>

      {/* Password Reset Modal */}
      {isResetModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Reset Password</h3>
            <input
              type="email"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 mb-4"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsResetModalOpen(false)}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                onClick={handleForgotPassword}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                Send Reset Link
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const SearchResults = ({ results = [], onNewSearch }) => {
  const [expanded, setExpanded] = useState({});
  const [comments, setComments] = useState({});
  const [sectionExpanded, setSectionExpanded] = useState({});

  // Reset state when new search is performed
  useEffect(() => {
    setExpanded({});
    setComments({});
    // Initialize media sections as expanded for all results
    setSectionExpanded(prevState => {
      const newState = {};
      results.forEach(result => {
        if (result) {
          const resultId = result.id || Math.random().toString();
          newState[resultId] = { ...prevState[resultId], media: true };
        }
      });
      return newState;
    });
    localStorage.removeItem('investigatorConclusion');
  }, [onNewSearch, results]);


  const handleCommentsChange = (resultId, newComment) => {
    setComments(prev => ({
      ...prev,
      [resultId]: newComment
    }));
  };

  const toggleSection = (resultId, section) => {
    setSectionExpanded(prev => ({
      ...prev,
      [resultId]: {
        ...(prev[resultId] || {}),
        [section]: !(prev[resultId]?.[section])
      }
    }));
  };

  const renderSectionWithToggle = (resultId, title, content, section) => {
    if (!content) return null;
    const isExpanded = sectionExpanded[resultId]?.[section];
    return (
      <div className="mt-4 border rounded-lg p-4">
        <div 
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleSection(resultId, section)}
        >
          <h3 className="text-lg font-medium">{title}</h3>
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
        {isExpanded && <div className="mt-4">{content}</div>}
      </div>
    );
  };

  const renderEntityBox = ({ name, sourceUrl, riskTopic, idName, idNumber, relationship, country, date, additionalFields = {} }) => (
    <div className="border rounded-lg p-4 mb-4 bg-white shadow-sm">
      <h4 className="text-lg font-medium mb-2">
        {sourceUrl ? renderLinks([sourceUrl], name) : name}
      </h4>
      {riskTopic && <p className="mb-1"><strong>Risk:</strong> {getRiskTag(riskTopic)}</p>}
      {idName && idNumber && <p className="mb-1"><strong>{idName}:</strong> {idNumber}</p>}
      {relationship && <p className="mb-1"><strong>Relationship:</strong> {relationship}</p>}
      {country && <p className="mb-1"><strong>Country:</strong> {getCountryName(country)}</p>}
      {date && <p className="mb-1"><strong>Date:</strong> {formatDate(date)}</p>}
      {Object.entries(additionalFields).map(([key, value]) => 
        value && <p key={key} className="mb-1"><strong>{key}:</strong> {value}</p>
      )}
    </div>
  );

  // Define the renderBasicFields function
  const renderBasicFields = (properties) => {
    if (!properties) return null;

    const fieldDefinitions = [
      { label: "Type", key: "schema" },
      { label: "Name", key: "name", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
      { label: "Birthday", key: "birthDate", format: formatDate },
      { label: "Legal Form", key: "legalForm", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
      { label: "Parent Company", key: "parent", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
      { label: "Incorporation Date", key: "incorporationDate", format: formatDate },
      { label: "Dissolution Date", key: "dissolutionDate", format: formatDate },
      { label: "Place of birth", key: "birthPlace" },
      { label: "Gender", key: "gender", format: arr => Array.isArray(arr) ? arr.map(g => g.charAt(0).toUpperCase() + g.slice(1)).join(", ") : arr },
      { label: "Nationality", key: "nationality", format: getCountryName },
      { label: "Country", key: "country", format: getCountryName },
      { label: "Citizenship", key: "citizenship", format: getCountryName },
      { label: "Jurisdiction", key: "jurisdiction", format: getCountryName },
      { label: "Passport Number", key: "passportNumber" },
      { label: "Tax Number", key: "taxNumber" },
      { label: "VAT Number", key: "vatCode" },
      { label: "Address", key: "address" },
      { label: "Position", key: "position" },
      { label: "Education", key: "education" },
      { label: "Email", key: "email" },
      { label: "Website", key: "website" },
      { label: "SWIFT/BIC", key: "swiftBic" },
      { label: "LEI", key: "leiCode" },
      { label: "Registration Number", key: "registrationNumber" },
      { label: "Sector", key: "sector" },
      { label: "Status", key: "status" }
    ];

    return (
      <div className="space-y-4">
        {fieldDefinitions.map(({ label, key, format }) => {
          const value = properties[key];
          if (!value || (Array.isArray(value) && value.length === 0)) return null;

          const displayValue = format ? format(value) : 
            Array.isArray(value) ? value.join(", ") : value;

          return (
            <div key={key} className="flex flex-col">
              <span className="font-medium text-gray-700">{label}</span>
              <span className="text-gray-900">{displayValue}</span>
            </div>
          );
        })}
      </div>
    );
  };

  if (!Array.isArray(results) || results.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      {results.map((result, index) => {
        if (!result) return null;
        const resultId = result.id || `result_${index}`;
        const props = result.properties || {};

        return (
          <div key={resultId} className="border rounded-lg bg-white shadow-sm overflow-hidden">
            <div className="px-4 py-3 bg-gray-50">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium text-gray-900">
                  {getResultTitle(result)}
                </h3>
                <div className="flex items-center gap-4">
                  {typeof result.score === 'number' && result.score > 0 && (
                    <span className="text-sm text-gray-500">
                      <span className="hidden sm:inline">Match Score: </span>
                      {(result.score * 100).toFixed(2)}%
                    </span>
                  )}
                  <button
                    onClick={() => setExpanded(prev => ({ ...prev, [resultId]: !prev[resultId] }))}
                    className="p-1 hover:bg-gray-200 rounded-full"
                  >
                    {expanded[resultId] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                  </button>
                </div>
              </div>
            </div>

            {expanded[resultId] && (
              <div className="p-4">
                {/* Risk Tags */}
                {props.topics && Array.isArray(props.topics) && (
                  <div className="flex flex-wrap gap-2 mb-4">
                    {props.topics.map((topic, idx) => (
                      <span 
                        key={idx}
                        className="px-2 py-1 text-xs font-medium bg-red-100 text-red-800 rounded-full"
                      >
                        {getRiskTag(topic)}
                      </span>
                    ))}
                  </div>
                )}

                {/* Basic Fields */}
                {renderBasicFields(props)}

                {/* Sanctions */}
                {renderSectionWithToggle(
                  resultId,
                  'Sanctions',
                  props.sanctions?.map((sanction, idx) => (
                    <div key={idx} className="border rounded p-4 mb-4 bg-gray-50">
                      <h4 className="font-medium mb-2">
                        {renderLinks([sanction.properties?.sourceUrl?.[0]], sanction.properties?.title || sanction.caption)}
                      </h4>
                      <p><strong>Program:</strong> {sanction.properties?.program?.join(', ') || 'N/A'}</p>
                      <p><strong>Authority:</strong> {sanction.properties?.authority?.join(', ') || 'N/A'}</p>
                      <p><strong>Status:</strong> {sanction.properties?.status || 'Active'}</p>
                      <p><strong>Since:</strong> {formatDate(sanction.properties?.startDate)}</p>
                    </div>
                  )),
                  'sanctions'
                )}

                {/* Owners */}
                {renderSectionWithToggle(
                  resultId,
                  'Owners',
                  props.ownershipAsset?.map((ownership, idx) => renderEntityBox({
                    key: idx,
                    name: ownership.properties?.owner?.[0]?.caption,
                    sourceUrl: ownership.properties?.owner?.[0]?.properties?.sourceUrl?.[0],
                    riskTopic: ownership.properties?.owner?.[0]?.properties?.topics?.[0],
                    additionalFields: {
                      'Ownership': `${ownership.properties?.percentage || 0}%`,
                      'Start Date': formatDate(ownership.properties?.startDate)
                    }
                  })),
                  'owners'
                )}

                {/* Directors */}
                {renderSectionWithToggle(
                  resultId,
                  'Directors',
                  props.directorshipOrganization?.map((dir, idx) => renderEntityBox({
                    key: idx,
                    name: dir.properties?.director?.[0]?.properties?.name?.[0],
                    sourceUrl: dir.properties?.director?.[0]?.properties?.sourceUrl?.[0],
                    riskTopic: dir.properties?.director?.[0]?.properties?.topics?.[0],
                    additionalFields: {
                      'Role': dir.properties?.role?.[0],
                      'Start Date': formatDate(dir.properties?.startDate)
                    }
                  })),
                  'directors'
                )}

                {/* Assets */}
                {renderSectionWithToggle(
                  resultId,
                  'Assets',
                  props.ownershipOwner?.map((asset, idx) => renderEntityBox({
                    key: idx,
                    name: asset.properties?.asset?.[0]?.caption,
                    sourceUrl: asset.properties?.asset?.[0]?.properties?.sourceUrl?.[0],
                    riskTopic: asset.properties?.asset?.[0]?.properties?.topics?.[0],
                    additionalFields: {
                      'Asset Type': asset.properties?.asset?.[0]?.schema,
                      'Ownership': `${asset.properties?.percentage || 0}%`,
                      'Last Seen': formatDate(asset.properties?.asset?.[0]?.last_seen)
                    }
                  })),
                  'assets'
                )}

                {/* Links */}
                {renderSectionWithToggle(
                  resultId,
                  'Links',
                  props.unknownLinkFrom?.map((link, idx) => renderEntityBox({
                    key: idx,
                    name: link.properties?.subject?.[0]?.caption,
                    sourceUrl: link.properties?.subject?.[0]?.properties?.sourceUrl?.[0],
                    riskTopic: link.properties?.subject?.[0]?.properties?.topics?.[0],
                    country: link.properties?.subject?.[0]?.properties?.country?.[0],
                    additionalFields: {
                      'Type': link.properties?.subject?.[0]?.schema,
                      'Last Seen': formatDate(link.properties?.subject?.[0]?.last_seen)
                    }
                  })),
                  'links'
                )}

                {/* Associates */}
                {renderSectionWithToggle(
                  resultId,
                  'Associates',
                  props.associations?.map((assoc, idx) => renderEntityBox({
                    key: idx,
                    name: assoc.properties?.person?.[0]?.caption,
                    sourceUrl: assoc.properties?.sourceUrl?.[0],
                    riskTopic: assoc.properties?.person?.[0]?.properties?.topics?.[0],
                    country: assoc.properties?.person?.[0]?.properties?.country?.[0],
                    relationship: assoc.properties?.relationship?.map(r => 
                      r.charAt(0).toUpperCase() + r.slice(1)
                    ).join(', ')
                  })),
                  'associates'
                )}

                {/* Family */}
                {renderSectionWithToggle(
                  resultId,
                  'Family Members',
                  props.familyPerson?.map((family, idx) => renderEntityBox({
                    key: idx,
                    name: family.properties?.relative?.[0]?.caption,
                    sourceUrl: family.properties?.relative?.[0]?.properties?.sourceUrl?.[0],
                    riskTopic: family.properties?.relative?.[0]?.properties?.topics?.[0],
                    country: family.properties?.relative?.[0]?.properties?.country?.[0],
                    relationship: family.properties?.relationship?.map(r => 
                      r.charAt(0).toUpperCase() + r.slice(1)
                    ).join(', ')
                  })),
                  'family'
                )}

                {/* Media Results */}
                {renderSectionWithToggle(
                  resultId,
                  'Media Results',
                  <div>
                    {result.mediaResults?.sources && (
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        {result.mediaResults.sources.slice(0, 6).map((source, idx) => (
                          <div 
                            key={idx}
                            className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100"
                          >
                            <a 
                              href={source.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm font-medium text-blue-600 hover:text-blue-800"
                            >
                              {source.title}
                            </a>
                            <p className="text-xs text-gray-600 mt-2">{source.snippet}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    {result.mediaResults?.llm_response && (
                      <div 
                        className="prose max-w-none mt-4"
                        dangerouslySetInnerHTML={{ 
                          __html: formatLLMResponse(result.mediaResults.llm_response) 
                        }}
                      />
                    )}
                  </div>,
                  'media'
                )}

                {/* Comments */}
                <div className="mt-6">
                  <h4 className="text-md font-medium text-gray-900 mb-2">Investigator's Comments</h4>
                  <textarea
                    value={comments[resultId] || ''}
                    onChange={(e) => handleCommentsChange(resultId, e.target.value)}
                    className="w-full h-32 p-2 border rounded-md"
                    placeholder="Add your comments here..."
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}

      {/* Conclusion */}
      <div className="mt-8 border rounded-lg bg-white shadow-sm p-4">
        <h4 className="text-lg font-medium text-gray-900 mb-4">Investigation Conclusion</h4>
        <textarea
          value={localStorage.getItem('investigatorConclusion') || ''}
          onChange={(e) => localStorage.setItem('investigatorConclusion', e.target.value)}
          className="w-full h-48 p-2 border rounded-md"
          placeholder="Enter your final conclusion here..."
        />
      </div>

      {/* Print Button */}
      <div className="flex justify-center mt-8 print:hidden">
        <button
          onClick={() => window.print()}
          className="w-full btn-primary"
          >
          🖨️ Print Report
        </button>
      </div>
    </div>
  );
};


const SearchApp = ({ user }) => {
  const [activeTab, setActiveTab] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    individualName: '',
    dob: '',
    gender: '',
    organization: '',
    designation: '',
    companyName: '',
    country: '🌍 Global',
    matchThreshold: 80
  });

  useEffect(() => {
    setCurrentScreen(analytics, 'search_app');
    if (user) {
      setUserId(analytics, user.uid);
      setUserProperties(analytics, {
        user_type: user.email.endsWith('.edu') ? 'academic' : 'general',
        account_creation_date: user.metadata.creationTime
      });
    }
  }, [user]);

  const validateForm = () => {
    if (activeTab === 'individual') {
      if (!formData.individualName?.trim()) {
        setError('Name cannot be empty');
        return false;
      }
      if (formData.individualName.trim().split(' ').length === 1) {
        setError('Please enter a full name');
        return false;
      }
    } else {
      if (!formData.companyName?.trim()) {
        setError('Company name cannot be empty');
        return false;
      }
    }
    return true;
  };

  const handleSearch = async () => {
    if (!validateForm()) return;
    
    const searchStartTime = Date.now();
    setError(null);
    setLoading(true);
    setSearchResults([]);
    
    try {
      const searchData = {
        searchType: activeTab,
        individualName: activeTab === 'individual' ? formData.individualName : undefined,
        dob: activeTab === 'individual' ? formData.dob : undefined,
        gender: activeTab === 'individual' ? formData.gender : undefined,
        organization: activeTab === 'individual' ? formData.organization : undefined,
        designation: activeTab === 'individual' ? formData.designation : undefined,
        companyName: activeTab === 'company' ? formData.companyName : undefined,
        country: formData.country.split(' ')[1], // Remove emoji
        matchThreshold: formData.matchThreshold / 100
      };

      const cleanedData = Object.fromEntries(
        Object.entries(searchData).filter(([_, v]) => v !== undefined)
      );

      logEvent(analytics, 'search_started', {
        search_type: activeTab,
        country: formData.country
      });

      const response = await searchApi(searchData);
      
      response.results?.forEach((result, index) => {
        setTimeout(() => {
          setSearchResults(prev => [...prev, result]);
        }, index * 100);
      });

      logEvent(analytics, 'search_completed', {
        search_type: activeTab,
        duration: Date.now() - searchStartTime,
        result_count: response.results?.length || 0
      });

    } catch (error) {
      setError(error.message);
      logEvent(analytics, 'search_error', {
        search_type: activeTab,
        error_message: error.message,
        duration: Date.now() - searchStartTime
      });
    } finally {
      setLoading(false);
    }
  };

  const renderForm = () => {
    if (!activeTab) return null;
  
    return (
      <div className="space-y-4 mt-6">
        {activeTab === 'individual' ? (
          <>
            <input
              type="text"
              placeholder="Individual's Name (required)"
              className="input-field"
              value={formData.individualName}
              onChange={(e) => setFormData({...formData, individualName: e.target.value})}
            />
            <DatePicker
              value={formData.dob}
              onChange={(e) => setFormData({...formData, dob: e.target.value})}
            />
            <GenderSelect
              value={formData.gender}
              onChange={(e) => setFormData({...formData, gender: e.target.value})}
            />
            <input
              type="text"
              placeholder="Organization (improves accuracy)"
              className="input-field"
              value={formData.organization}
              onChange={(e) => setFormData({...formData, organization: e.target.value})}
            />
            <input
              type="text"
              placeholder="Designation (improves accuracy)"
              className="input-field"
              value={formData.designation}
              onChange={(e) => setFormData({...formData, designation: e.target.value})}
            />
          </>
        ) : (
          <input
            type="text"
            placeholder="Company Name (required)"
            className="input-field"
            value={formData.companyName}
            onChange={(e) => setFormData({...formData, companyName: e.target.value})}
          />
        )}
  
        {/* Add the country selection field here */}
        <select
          value={formData.country}
          onChange={(e) => setFormData({ ...formData, country: e.target.value })}
          className="input-field"
        >
          {Object.keys(COUNTRY_MAPPING).map((code) => (
            <option key={code} value={COUNTRY_MAPPING[code]}>
              {COUNTRY_MAPPING[code]}
            </option>
          ))}
        </select>
  
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Minimum Match Score: {formData.matchThreshold}%
          </label>
          <input
            type="range"
            min="0"
            max="100"
            value={formData.matchThreshold}
            onChange={(e) => setFormData({...formData, matchThreshold: parseInt(e.target.value)})}
            className="w-full accent-sky-600"
          />
        </div>
  
        <button 
          className="btn-primary w-full"
          onClick={handleSearch}
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
              <span className="ml-2">Searching...</span>
            </div>
          ) : (
            'Search'
          )}
        </button>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <h1 className="text-2xl font-bold text-gray-900">Hound</h1>
              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-800">
                BETA
              </span>
            </div>
            <div className="flex items-center">
              <UserMenu user={user} onSignOut={() => {}} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold mb-2">Welcome to Hound</h2>
              <p className="text-lg text-gray-700">Begin your search by selecting a target type below</p>
            </div>
            
            <div className="flex space-x-4">
              <button
                className={`flex-1 py-2 px-4 rounded-md ${
                  activeTab === 'individual'
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => setActiveTab('individual')}
              >
                Individual
              </button>
              <button
                className={`flex-1 py-2 px-4 rounded-md ${
                  activeTab === 'company'
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => setActiveTab('company')}
              >
                Company
              </button>
            </div>

            {renderForm()}
          </div>

          {searchResults.length > 0 && (
            <SearchResults 
              results={searchResults}
              onNewSearch={() => setSearchResults([])}
            />
          )}
        </div>
      </div>
    </div>
  );

};


const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);

      // Track user authentication state changes
      logEvent(analytics, 'auth_state_changed', {
        is_authenticated: Boolean(user)
      });

      if (user) {
        setUserId(analytics, user.uid);
        setUserProperties(analytics, {
          user_type: user.email.endsWith('.edu') ? 'academic' : 'general',
          auth_method: user.providerData[0]?.providerId || 'unknown',
          account_creation_date: user.metadata.creationTime
        });
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  return user ? (
    <SearchApp 
      user={user} 
    />
  ) : (
    <LandingPage 
      onLogin={() => {
        // Handle any post-login logic if needed
      }} 
    />
  );
};

export default App;
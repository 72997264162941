// src/services/api.js
const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://us-central1-hound-80be6.cloudfunctions.net/app'
  : 'http://localhost:8000';

export const searchApi = async (searchData) => {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchData)
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.detail || 'Search failed');
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};